import React from 'react';
import { Page, Navbar, BlockTitle, List, ListItem } from 'framework7-react';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      NewsCategories: props.f7route.context.NewsCategories,
      translate: {
        'en': {
          'news': 'News',
          'newsandupdates': 'News and updates for you',
          'back': 'Back'
        },
        'cs': {
          'news': 'Novinky',
          'newsandupdates': 'Novinky a aktualizace pro vás',
          'back': 'Dozadu'
        },
        'nl': {
          'news': 'Nieuws en update voor u',
          'newsandupdates': 'Nieuws en updates voor u',
          'back': 'Vorige'
        },
        'fr': {
          'news': 'Actualités',
          'newsandupdates': 'Nouvelles et mises à jour pour toi',
          'back': 'Précédent'
        },
        'de': {
          'news': 'Neuigkeiten',
          'newsandupdates': 'Neuigkeiten und Updates für dich',
          'back': 'Zurück'
        },
        'pl': {
          'news': 'Aktualności',
          'newsandupdates': 'Aktualności i aktualizacje dla Ciebie',
          'back': 'Wróć'
        },
        'es': {
          'news': 'Noticias',
          'newsandupdates': 'Noticias y novedades para ti',
          'back': 'Atrás'
        }
      }
    };

  }

  render() {
    return (
      <Page name="news-categories">
        <Navbar title={`${this.state.translate[localStorage.getItem('lang')].news}`} backLink={`${this.state.translate[localStorage.getItem('lang')].back}`}/>
        <BlockTitle>{this.state.translate[localStorage.getItem('lang')].newsandupdates}</BlockTitle>
        <List>
        {this.state.NewsCategories.map((category, index) => (
          <ListItem title={`${category.name}`} key={index} link={`/news-category-list-posts/${category.id}/`}></ListItem>
        ))}
        </List>
      </Page>
    );
  }
}