import HomePage from '../pages/home.jsx';
import PanelLeft from '../pages/panel-left.jsx';
import PanelRight from '../pages/panel-right.jsx';
import FoodInaBoxPage from '../pages/food-in-a-box.jsx';
import EventPage from '../pages/event.jsx';
import EventsPage from '../pages/events.jsx';
import FarmerPage from '../pages/farmer.jsx';
import FarmersPage from '../pages/farmers.jsx';
import NewsCategories from '../pages/news-categories.jsx';
import NewsCategoryListPosts from '../pages/news-category-list-posts.jsx';
import NewsSinglePost from '../pages/news-single-post.jsx';
import PartnersPage from '../pages/partners.jsx';
import LocalCommunityPage from '../pages/local-community.jsx';
import AboutTheTechnologyPage from '../pages/about-the-technology.jsx';
import ContactPage from '../pages/contact.jsx';

import NotFoundPage from '../pages/404.jsx';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-210545481-1');

const WP = 'https://foxlink.app';
const WPAPI = WP + '/wp-json/wp/v2/';
const WPACF = WP + '/wp-json/acf/v3/';

var language = false;

if('lang' in localStorage){
  language = localStorage.getItem('lang');
} else {
  localStorage.setItem('lang', 'en');
  language = window.navigator.language.split(/[-_]/)[0];
}

if(!( (language == 'en') ||
      (language == 'cs') ||
      (language == 'nl') ||
      (language == 'fr') ||
      (language == 'de') ||
      (language == 'pl') ||
      (language == 'es')
  )) {
    language = 'en';
    localStorage.setItem('lang', 'en');
}

var langvar;
if ( language == 'en' ) {
  langvar = '&';
} else {
  langvar = '&lang=';
}
var routes = [{
    path: '/',
    //beforeEnter: checkAuth,
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/', [], 'FoxLink' );

      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();
      
      if(localStorage.getItem('isLogin')) {
        app.request.setup({
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
          }
        });

        app.request.json(WPAPI + 'frontpage?' + langvar + language, function(data) {
  
          // Hide Preloader
          
          // Resolve route to load page
          resolve({
            component: HomePage,
          }, {
            context: {
              frontpage: data,
            }
          });
  
        });
      } else {
        app.loginScreen.open('#my-login-screen');
        reject();
      }
      
      app.preloader.hide();

    }
  },
  {
    path: '/panel-right/',
    beforeEnter: checkAuth,
    async: function(routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();
      
      app.request.setup({
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
        }
      });

      app.request.json(WP + '/wp-json/wp/v2/users/me', function(data) {
        
        localStorage.setItem('userrole', data.roles[0]);
        // Hide Preloader
        app.preloader.hide();

        resolve({
          component: PanelRight,
        }, {
          context: {
            userme: data,
          }
        });

      }, function(error){
        localStorage.clear();
        window.location.reload(false);
      });
    }
  },
  {
    path: '/panel-left/',
    beforeEnter: checkAuth,
    async: function(routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();
      
      app.request.setup({
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
        }
      });

      app.request.json(WP + '/wp-json/menus/v1/menus/left-menu-' + language, function(data) {
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve({
          component: PanelLeft,
        }, {
          context: {
            navigation: data,
          }
        });

      });
      
    }    
  },
  {
    path: '/food-in-a-box/',
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/food-in-a-box/', [], 'Food in a Box' );
      
      var wp_foodinabox = {
        'en': 184,
        'cs': 228,
        'nl': 232,
        'fr': 235,
        'de': 238,
        'pl': 245,
        'es': 249
      };

      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      app.request.json(WPACF + 'pages/' + wp_foodinabox[language], function(data) {

        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve({
          component: FoodInaBoxPage,
        }, {
          context: {
            foodinabox: data,
          }
        });

      });
    }
  },
  {
    // path: '/events/:eventCategoryId',
    path: '/events/',
    beforeEnter: checkAuth,
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/events/', [], 'Events');
      
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();
      
      app.request.setup({
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
        }
      });

      app.request.json(WPACF + 'events/?' + langvar + language + '&per_page=50', function(data) {

        // Hide Preloader
        app.preloader.hide();

        resolve({
          component: EventsPage,
        }, {
          context: {
            events: data,
          }
        });

      });
    }
  },
  {
    path: '/event/:eventId/',
    async: function(routeTo, routeFrom, resolve, reject) {
      
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      if(localStorage.getItem('isLogin')) {
        var eventId = routeTo.params.eventId;
        app.request.setup({
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
          }
        });
        
        app.request.json(WPAPI + 'events/' + eventId + '?' + langvar + language, function(data) {
  
          ReactGA.pageview( '/' + language + '/event/' + slugify(data.acf.title), [], data.acf.title );
  
          resolve({
            component: EventPage,
          }, {
            context: {
              event: data,
            }
          });
  
        });
      } else {
        app.loginScreen.open('#my-login-screen');
        reject();
      }
      
      app.preloader.hide();  
    }
  },
  {
    path: '/farmers/',
    beforeEnter: checkAuth,
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/farmers/', [], 'Farmers');
      
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      app.request.setup({
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
        }
      });
      app.request.json(WPAPI + 'farmers/?' + langvar + language + '&per_page=50', function(data) {

        // Hide Preloader
        app.preloader.hide();

        resolve({
          component: FarmersPage,
        }, {
          context: {
            farmers: data,
          }
        });

      });
    }
  },
  {
    path: '/farmer/:farmerId/',
    async: function(routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      if(localStorage.getItem('isLogin')) {
        var farmerId = routeTo.params.farmerId;
        app.request.setup({
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
          }
        });
  
        app.request.json(WPAPI + 'farmers/' + farmerId + '?' + langvar + language, function(data) {
  
          ReactGA.pageview( '/' + language + '/farmer/' + slugify(data.acf.name), [], data.acf.name );
  
          resolve({
            component: FarmerPage,
          }, {
            context: {
              farmer: data,
            }
          });
  
        });
      } else {
        app.loginScreen.open('#my-login-screen');
        reject();
      }

      app.preloader.hide();
          
    }
  },
  {
    path: '/news-categories/',
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/news-categories/', [], 'News Categories');
      
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();
      if(!(language == 'en') ) {
        app.request.setup({
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
          }
        });
      } else {
        app.request.setup({
          headers: null
        });
      }
      app.request.json(WPAPI + 'categories/' + '?' + langvar + language +'&exclude=89,90,91,92,93,94,95,96,97,98,99,100,101,102', function(data) {

        // Hide Preloader
        app.preloader.hide();

        resolve({
          component: NewsCategories,
        }, {
          context: {
            NewsCategories: data,
          }
        });

      });
}
  },
  {
    path: '/news-category-list-posts/:categoryId/',
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/news-category-list-posts/', [], 'News Post');

      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();
      
      var categoryId = routeTo.params.categoryId;

      if(!(language == 'en') ) {
        app.request.setup({
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
          }
        });
      } else {
        app.request.setup({
          headers: null
        });
      }
      app.request.json(WPAPI + 'posts?' + langvar + language +'&categories=' + categoryId, function(data) {

        // Hide Preloader
        app.preloader.hide();

        resolve({
          component: NewsCategoryListPosts,
        }, {
          context: {
            NewsCategoryListPosts: data,
          }
        });

      });
    }
  },
  {
    path: '/news/:newsId/',
    async: function(routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();
      
      var newsId = routeTo.params.newsId;

      if(language == 'en') {
        app.request.setup({
          headers: null
        });
      }
      
      app.request.json(WPAPI + 'posts/' + newsId + '?' + '&_fields=acf,id,excerpt,title,link,content,date', function(data) {

        // ReactGA.pageview( '/' + language + '/' + slugify(data.title.rendered), [], data.title.rendered );
        
        // Hide Preloader
        app.preloader.hide();

        resolve({
          component: NewsSinglePost,
        }, {
          context: {
            news: data,
          }
        });

      });
    }
  },
  {
    path: '/partners/',
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/partners/', [], 'Partners');
      
      var wp_partners = {
        'en': 173,
        'cs': 181,
        'nl': 269,
        'fr': 272,
        'de': 275,
        'pl': 279,
        'es': 282
      };

      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      app.request.json(WPACF + 'pages/' + wp_partners[language], function(data) {

        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve({
          component: PartnersPage,
        }, {
          context: {
            partners: data,
          }
        });

      });
    }
  },
  {
    path: '/local-community/',
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/local-community/', [], 'Local Community');
      
      var wp_local_comunity = {
        'en': 668,
        'cs': 670,
        'nl': 674,
        'fr': 672,
        'de': 676,
        'pl': 678,
        'es': 682
      };

      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      app.request.json(WPACF + 'pages/' + wp_local_comunity[language], function(data) {

        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve({
          component: LocalCommunityPage,
        }, {
          context: {
            localcommunity: data,
          }
        });

      });
    }
  },
  {
    path: '/about-the-technology/',
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/about-the-technology/', [], 'About the Technology');
      
      var wp_about_the_technology = {
        'en': 626,
        'cs': 637,
        'nl': 647,
        'fr': 640,
        'de': 651,
        'pl': 632,
        'es': 644
      };

      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      app.request.json(WPACF + 'pages/' + wp_about_the_technology[language], function(data) {

        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve({
          component: AboutTheTechnologyPage,
        }, {
          context: {
            about: data,
          }
        });

      });
    }
  },
  {
    path: '/contact-in-your-region/',
    async: function(routeTo, routeFrom, resolve, reject) {
      
      ReactGA.pageview( '/' + language + '/contact-in-your-region/', [], 'Contact');
      
      var wp_contact = {
        'en': 7904,
        'cs': 7905,
        'nl': 7908,
        'fr': 7907,
        'de': 7911,
        'pl': 7909,
        'es': 7906
      };

      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();
      
      app.request.setup({
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('isLogin')).token
        }
      });

      app.request.json(WPACF + 'pages/' + wp_contact[language], function(data) {

        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve({
          component: ContactPage,
        }, {
          context: {
            contact: data,
          }
        });

      });
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

function checkAuth(to, from, resolve, reject) {
  // Router instance
  var router = this;
  
  // App instance
  var app = router.app;
  
  if (localStorage.getItem('isLogin') == null || JSON.parse(localStorage.getItem('isLogin')).status == false) {
    app.loginScreen.open('#my-login-screen');
    reject();
  } else {
    resolve();
  }
}

function slugify(string) {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export default routes;
