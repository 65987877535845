import React from 'react';
import { Page, Navbar, List, ListItem, Searchbar} from 'framework7-react';

import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@google/markerclusterer';

export default class extends React.Component {
  constructor(props) {
    super(props);

    const items = props.f7route.context.farmers;
    this.state = {
      items,
      vlData: {
        items: [],
      },
      translate: {
        'en': {
          'filtername': 'Filter by product',
          'filterproduct': 'Filter by product',
          'farms': 'Farms'
        },
        'cs': {
          'filtername': 'Filtrovat podle produktu',
          'filterproduct': 'Filtrovat podle produktu',
          'farms': 'Farmy'
        },
        'nl': {
          'filtername': 'Filter op product',
          'filterproduct': 'Filter op product',
          'farms': 'Bedrijven'
        },
        'fr': {
          'filtername': 'Filtrer par produit',
          'filterproduct': 'Filtrer par produit',
          'farms': 'Fermes'
        },
        'de': {
          'filtername': 'Nach Produkt filtern',
          'filterproduct': 'Nach Produkt filtern',
          'farms': 'Bauernhöfe'
        },
        'pl': {
          'filtername': 'Filtruj według produktu',
          'filterproduct': 'Filtruj według produktu',
          'farms': 'Gospodarstwa rolne'
        },
        'es': {
          'filtername': 'Filtrar por producto',
          'filterproduct': 'Filtrar por producto',
          'farms': 'Comercios'
        }
      }
    };
  }

  searchAll(query, items) {

    const found = [];
    for (let i = 0; i < items.length; i += 1) {
      // if (items[i].acf.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
      for (let p = 0; p < items[i].acf.products.length; p += 1) {
        if (items[i].acf.products[p].product.post_title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
      }
    }

    return found; // return array with mathced indexes
  }

  renderExternal(vl, vlData) {
    this.setState({ vlData });
  }
  
  onlyUnique(value, index, self) { 
      return self.indexOf(value) === index;
  }
  
  smartFilter() {

    const selectedOptions = this.$f7.smartSelect.get().selectEl.selectedOptions;
    const found = [];
    
    for (let so = 0; so < selectedOptions.length; so += 1) {
      for (let i = 0; i < this.state.items.length; i += 1) {
        for (let p = 0; p < this.state.items[i].acf.products.length; p += 1) {
          if ( this.state.items[i].acf.products[p].product.post_title.toLowerCase().indexOf(selectedOptions[so].value ) >= 0 ) {
            found.push(this.state.items[i]);
          }
        }
      }
    }

    this.setState({
      vlData: {
        items: found.filter( this.onlyUnique ),
      }
    });
  }

  render() {
    return (
      <Page name="farmers">
        <Navbar title={`${this.state.translate[localStorage.getItem('lang')].farms}`} />
          <div style={{ height: '25vh', width: '100%', position: 'fixed', zIndex: 1 }} className="farmers-map">
            <GoogleMapReact
              bootstrapURLKeys={{key: `AIzaSyD4upTz-ZvVD7dNlSGL4X4EGSF0ItsHvOE`}}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({map, maps}) => this.setGoogleMapRef(map, maps)}
              defaultCenter={{lat: 50.8450745, lng: 4.3120767}}
              defaultZoom={3}
              options={{streetViewControl: true}}
              
    greatPlaces={
      {id: 'A', lat: 50.8450745, lng: 4.3120767},
      {id: 'B', lat: 59.724, lng: 30.080}
    }
    
    
            />
          </div>
          <Searchbar
            searchContainer=".virtual-list"
            searchItem="li"
            searchIn=".item-title, .item-subtitle"
            placeholder={`${this.state.translate[localStorage.getItem('lang')].filtername}`}
            disableButton={!this.$theme.aurora}
          ></Searchbar>
          <List
            className="farmList searchbar-found"
            medialList
            virtualList
            virtualListParams={{ 
              items: this.state.items,
              searchAll: this.searchAll, 
              renderExternal: this.renderExternal.bind(this), 
              height: this.$theme.ios ? 63 : (this.$theme.md ? 73 : 46)
            }}
          >
            <ul>
              {this.state.vlData.items.map((item, index) => (
                <ListItem
                  key={index}
                  mediaItem
                  link={`/farmer/${item.id}/`}
                  title={item.acf.name}
                  subtitle={(item.acf.products.length > 0 ?
                    item.acf.products.map((product, index) => ( product.product.post_title + ' ' )) : ('-') 
                  )}
                  style={{top: `${this.state.vlData.topPosition}px`}}
                  virtualListIndex={this.state.vlData.items.indexOf(item)}
                >
                <img slot="media" src={`${item.acf.logo.url}`} width="44" />
                </ListItem>
              ))}
            </ul>
          </List>
      </Page>
    );
  }
  
  setGoogleMapRef(map, maps) {

    var acfLocations = [];
    if (this.state.items) {
      {this.state.items.map((farmer, index) => (
        acfLocations.push(
        { lat: farmer.acf.location.lat, lng: farmer.acf.location.lng })
      ))}
    }

    this.googleMapRef = map;
    this.googleRef = maps;
    let locations = acfLocations;
    let markers = locations && locations.map((location) => {
      return new this.googleRef.Marker({ position: location });
    });
    let markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      gridSize: 10,
      minimumClusterSize: 2
    });
  }

}