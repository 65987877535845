import React from 'react';
import { Page, BlockTitle, List, ListItem, Navbar } from 'framework7-react';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: props.f7route.context.navigation,
    };
    
  }

  render() {
    return (
      <Page>
        <Navbar title="FOXLINK App"/>
        <BlockTitle>{this.state.navigation.title}</BlockTitle>
        <List>
          {this.state.navigation.items.map((menuitem, index) => (
          <ListItem key={index} link={`${menuitem.url}`} title={`${menuitem.post_title}`} panelClose></ListItem>
          ))}
        </List>
      </Page>
    );
  }

}
