import React from 'react';
import { Button, Page, Toggle, Link, Block, BlockTitle, List, ListInput, PageContent, Toolbar, Sheet, Navbar } from 'framework7-react';
import privacy from '../static/content/privacy.js';

export default class extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      userme: props.f7route.context.userme,
      
      translate: {
        'en': {
          'settings': 'Settings',
          'pushmessage': 'Push messages',
          'myprofile': 'My profile',
          'nickname': 'Nickname/username',
          'dateofbirth': 'Date of birth',
          'gender': 'Gender',
          'female': 'Feminine',
          'male': 'Masculine',
          'other': 'Other',
          'birth': 'Your birth date',
          'area': 'Area',
          'urban': 'Urban',
          'rural': 'Rural',
          'logout': 'Log out',
        },
        'cs': {
          'settings': 'Nastavení',
          'pushmessage': 'Push oznámení',
          'myprofile': 'Můj profil',
          'nickname': 'Uživatelské jméno',
          'dateofbirth': 'Datum narození',
          'gender': 'Rod',
          'female': 'Ženský',
          'male': 'Mužský',
          'other': 'Jiný',
          'birth': 'Datum narození',
          'area': 'Plocha',
          'urban': 'Městský',
          'rural': 'Venkovský',
          'logout': 'Odhlásit se',
        },
        'nl': {
          'settings': 'Instellingen',
          'pushmessage': 'Automatische meldingen',
          'myprofile': 'Mijn profiel',
          'nickname': 'Gebruikersnaam',
          'dateofbirth': 'Geboortedatum',
          'gender': 'Geslacht',
          'female': 'Vrouw',
          'male': 'Man',
          'other': 'Andere',
          'birth': 'Geboortedatum',
          'area': 'Regio',
          'urban': 'Stedelijk',
          'rural': 'Landelijk',
          'logout': 'Uitloggen',
        },
        'fr': {
          'settings': 'Paramètres',
          'pushmessage': 'Notifications automatique',
          'myprofile': 'Mon profil',
          'nickname': 'Nom d\'utilisateur',
          'dateofbirth': 'Date de naissance',
          'gender': 'Sexe',
          'female': 'Femme',
          'male': 'Homme',
          'other': 'Autre',
          'birth': 'Date de naissance',
          'area': 'Région',
          'urban': 'Urbain',
          'rural': 'Rural',
          'logout': 'Déconnexion',
        },
        'de': {
          'settings': 'Einstellungen',
          'pushmessage': 'Push-Benachrichtigungen',
          'myprofile': 'Mein Profil',
          'nickname': 'Benutzername',
          'dateofbirth': 'Geburtsdatum',
          'gender': 'Geschlecht',
          'female': 'Weiblich',
          'male': 'Männlich',
          'other': 'Anderes',
          'birth': 'Geburtsdatum',
          'area': 'Bereich',
          'urban': 'Städtisch',
          'rural': 'Ländlich',
          'logout': 'Abmelden',
        },
        'pl': {
          'settings': 'Ustawienia',
          'pushmessage': 'Komunikat pchający',
          'myprofile': 'Mój profil',
          'nickname': 'Nazwa użytkownika',
          'dateofbirth': 'Data urodzenia',
          'gender': 'Płeć',
          'female': 'Kobieta',
          'male': 'Mężczyzna',
          'other': 'Inne',
          'birth': 'Data urodzenia',
          'area': 'Obszar',
          'urban': 'Miejski',
          'rural': 'Wiejski',
          'logout': 'Wylogować się',
        },
        'es': {
          'settings': 'Configuración',
          'pushmessage': 'Notificaciones automáticas',
          'myprofile': 'Mi perfil',
          'nickname': 'Nombre de usuario',
          'dateofbirth': 'Fecha de nacimiento',
          'gender': 'Género',
          'female': 'Femenino',
          'male': 'Masculino',
          'other': 'Otro',
          'birth': 'Fecha de nacimiento',
          'area': 'Área',
          'urban': 'Urbana',
          'rural': 'Rural',
          'logout': 'Cerrar la sesión',
        }
      }
    };
    
    // this.state = {
    //  lang: localStorage.getItem('lang')
    // };
  }
  
  render() {
    return (
     <Page>
        <Navbar title={`${this.state.translate[localStorage.getItem('lang')].settings}`}/>
        <BlockTitle>{this.state.translate[localStorage.getItem('lang')].myprofile}</BlockTitle>
        <List noHairlinesMd>
          <ListInput
            type="text"
            placeholder="Nickname"
            info={`${this.state.translate[localStorage.getItem('lang')].nickname}`}
            readonly
            defaultValue={`${this.state.userme.name}`}
          />

          <ListInput
            info={`${this.state.translate[localStorage.getItem('lang')].gender}`}
            type="select"
            placeholder="Please choose..."
            readonly
            defaultValue={`${this.state.userme.gender}`}
          >
            <option value="female">{this.state.translate[localStorage.getItem('lang')].female}</option>
            <option value="male">{this.state.translate[localStorage.getItem('lang')].male}</option>
            <option value="other">{this.state.translate[localStorage.getItem('lang')].other}</option>
          </ListInput>
          
          {/* <ListInput
            info={`${this.state.translate[localStorage.getItem('lang')].dateofbirth}`}
            type="datepicker"
            placeholder="Your birth date"
            readonly
            value={[new Date(this.state.userme.age)]}
            readonly
          /> */}
          
          <ListInput
            info={`${this.state.translate[localStorage.getItem('lang')].area}`}
            type="select"
            defaultValue={`${this.state.userme.area}`}
            readonly
            placeholder="Please choose..."
          >
            <option value="urban">{this.state.translate[localStorage.getItem('lang')].urban}</option>
            <option value="rural">{this.state.translate[localStorage.getItem('lang')].rural}</option>
          </ListInput>
          
        </List>
        <Button raised fill round onClick={() => this.logOut()}>{this.state.translate[localStorage.getItem('lang')].logout}</Button>
        <Block>
          <Link popupOpen=".terms">{privacy[localStorage.getItem('lang')].consenttitle}</Link>
        </Block>
        <Block>
          <Link popupOpen=".policy">{privacy[localStorage.getItem('lang')].policytitle}</Link>
        </Block>
        
        <Sheet className="terms" opened={this.state.sheetOpened} onSheetClosed={() => {this.setState({sheetOpened: false})}}>
          <Toolbar>
            <div className="left"></div>
            <div className="right">
              <Link sheetClose>Close</Link>
            </div>
          </Toolbar>
          {/*  Scrollable sheet content */}
          <PageContent>
            <Block>
              <p dangerouslySetInnerHTML={{__html: privacy[localStorage.getItem('lang')].consenttext }} />
            </Block>
          </PageContent>
        </Sheet>
        <Sheet className="policy" opened={this.state.sheetOpened} onSheetClosed={() => {this.setState({sheetOpened: false})}}>
          <Toolbar>
            <div className="left"></div>
            <div className="right">
              <Link sheetClose>Close</Link>
            </div>
          </Toolbar>
          {/*  Scrollable sheet content */}
          <PageContent>
            <Block>
              <p dangerouslySetInnerHTML={{__html: privacy[localStorage.getItem('lang')].policytext }} />
            </Block>
          </PageContent>
        </Sheet>
        
      </Page>
    );
  }

  logOut(e) {
    this.$f7.preloader.show();
    localStorage.removeItem('isLogin');
    localStorage.removeItem('isRegister');
    localStorage.setItem('lang', 'en');
    window.location.reload(false);
  }
  
}