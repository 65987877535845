import React from 'react';
import { Page, Navbar, Button, Block, BlockTitle, Swiper, SwiperSlide, Chip, Icon, Row, Col, Link, List, ListInput, ListItem } from 'framework7-react';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      news: props.f7route.context.news,
      date: props.f7route.context.news.date,
      like: props.f7route.context.news.acf.like,
      dislike: props.f7route.context.news.acf.dislike,
      translate: {
        'en' : {
            'back': 'Back',
            'comments': 'Comments',
            'typecommenthere': 'Type comment here',
            'yourcomment': 'Your comment',
            'submit': 'Submit'
        },
        'cs' : {
            'back': 'Dozadu',
            'comments': 'Komentáře',
            'typecommenthere': 'Napište komentář zde',
            'yourcomment': 'Váš komentář',
            'submit': 'Odeslat'
        },
        'nl' : {
            'back': 'Vorige',
            'comments': 'Commentaar',
            'typecommenthere': 'Schrijf commentaar hier',
            'yourcomment': 'Uw commentaar',
            'submit': 'Verzenden'
        },
        'fr' : {
            'back': 'Précédent',
            'comments': 'Commentaires',
            'typecommenthere': 'Écrire un commentaire ici',
            'yourcomment': 'Votre commentaire',
            'submit': 'Soumettre'
        },
        'de' : {
            'back': 'Zurück',
            'comments': 'Kommentare',
            'typecommenthere': 'Schreiben Sie Ihren Kommentar hier',
            'yourcomment': 'Ihr Kommentar',
            'submit': 'Senden'
        },
        'pl' : {
            'back': 'Wróć',
            'comments': 'Komentarze',
            'typecommenthere': 'Napisz komentarz tutaj',
            'yourcomment': 'Twój komentarz',
            'submit': 'Wyślij'
        },
        'es' : {
            'back': 'Atrás',
            'comments': 'Comentarios',
            'typecommenthere': 'Escriba su comentario aquí',
            'yourcomment': 'Su comentario',
            'submit': 'Enviar'
        }
      }
    };
    
    var dt = new Date( this.state.news.date );
    this.date = {
      day: dt.getDate(),
      month: dt.getMonth()+1,
      year: dt.getFullYear()
    };
    
    console.log(this.date)
    
  }
  render() {
    
    const shareUrl = 'https://foxlink.app/#!/news/'+ this.state.news.id + '/';
    const title = 'FOXLINK - ' + this.state.news.title.rendered;
    
    return (
      <Page name="news">
        <Navbar title={`${this.state.news.title.rendered}`} backLink="Back" />
        {(
          (() => {
            if (this.state.news.acf.gallery !== false) {
              return <Swiper pagination>
                {this.state.news.acf.gallery.map((gallery_image, index) => (
                  <SwiperSlide key={index}>
                    <img alt={`${gallery_image.title}`} src={`${gallery_image.url}`} />
                  </SwiperSlide>
                ))}
              </Swiper>;
            }
          })()
        )}
        <Block strong className="no-margin-bottom">
          <div dangerouslySetInnerHTML={{__html: this.state.news.content.rendered}} />
          <Chip text={`${this.date.day + '.' + this.date.month + '.' + this.date.year + '.'}`} mediaBgColor="blue">
            <Icon slot="media" f7="clock"></Icon>
          </Chip>
          <Link onClick={() => {
            this.like('like', this.state.news.id);
            this.setState({like: '+'});
          }}>
            <Chip margin-left text={`${this.state.like}`} outline mediaBgColor="green">
              <Icon slot="media" f7="hand_thumbsup"></Icon>
            </Chip>
          </Link>
          <Link onClick={() => {
            this.like('dislike', this.state.news.id);
            this.setState({dislike: '+'});
          }}>
            <Chip text={`${this.state.dislike}`} mediaBgColor="red">
              <Icon slot="media" f7="heart_slash"></Icon>
            </Chip>
          </Link>
        </Block>
        
        <Block>
             <Row className="Demo__container">
                <Col className="Demo__some-network">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
        
                </Col>
        
                <Col className="Demo__some-network">
                  <FacebookMessengerShareButton
                    url={shareUrl}
                    appId="521270401588372"
                    className="Demo__some-network__share-button"
                  >
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton>
                </Col>
        
                <Col className="Demo__some-network">
                  <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
        
                </Col>
        
                <Col className="Demo__some-network">
                  <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
        
                </Col>
        
                <Col className="Demo__some-network">
                  <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </Col>

              </Row>
        </Block>
        <Block>
          <BlockTitle>{`${this.state.translate[localStorage.getItem('lang')].comments}`}</BlockTitle>
          <List form onSubmit={(e)=>this.comment(e, this.state.news.id)}>
            <ListInput
              label={`${this.state.translate[localStorage.getItem('lang')].typecommenthere}`}
              type="textarea"
              placeholder={`${this.state.translate[localStorage.getItem('lang')].yourcomment}`}
              name="commentcontent"
            >
            </ListInput>
            <Button type="submit">{`${this.state.translate[localStorage.getItem('lang')].submit}`}</Button>
          </List>
            {(
              (() => {
                if (typeof(this.state.news._embedded) !== 'undefined') {
                  return <List mediaList>
                    {this.state.news._embedded.replies[0].map((reply, index) => (
                      <ListItem
                        key={index}
                        title={reply.author_name}
                        after={`${new Date(reply.date).getDate() + '.' + (new Date(reply.date).getMonth()+1) + '.' + new Date(reply.date).getFullYear() + '.' }`}
                        // text={`${reply.content.rendered}`}
                        text={`${reply.content.rendered.replace(/<[^>]+>/g, '') }`}
                      ></ListItem>
                    ))}
                    </List>;
                }
  
              })()
            )}
        </Block>
      </Page>
    );
  }

  like(type, post_id) {
    
    if('vote' in localStorage) {
      if (localStorage.getItem('vote') == post_id) {
      }
    } else {
      this.$f7.request.post('https://foxlink.app/wp-admin/admin-ajax.php', {
        action: 'like_dislike', 
        post_id: post_id,
        type: type
      }, function (data) {
        localStorage.setItem('vote', post_id);
      });
    }

  }
  
  comment(e, post_id) {
    e.preventDefault();
    const app = this.$f7;
    const formData = app.form.convertToData(e.target);
		app.dialog.alert("Thanks!");
    
    this.$f7.request.post('https://foxlink.app/wp-admin/admin-ajax.php', {
      action: 'comment', 
      post_id: post_id,
      user_id: localStorage.getItem('userid'),
      content: formData.commentcontent
    });
  }

  backLink(){
    if (window.location.hash.charAt(0) == "#") {
      return false;
    } else {
      return this.state.translate[localStorage.getItem('lang')].back;
    }
  }

}