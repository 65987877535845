import React from 'react';
import { Page, Navbar, Block, BlockTitle, Button, Swiper, List, ListItem, ListInput, SwiperSlide, Chip, Icon, Row, Col } from 'framework7-react';
import Moment from 'react-moment';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon
} from "react-share";

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event: props.f7route.context.event,
      like: props.f7route.context.event.acf.like,
      dislike: props.f7route.context.event.acf.dislike,
      translate: {
        'en' : {
            'back': 'Back',
            'comments': 'Comments',
            'typecommenthere': 'Type comment here',
            'yourcomment': 'Your comment',
            'submit': 'Submit'
        },
        'cs' : {
            'back': 'Dozadu',
            'comments': 'Komentáře',
            'typecommenthere': 'Napište komentář zde',
            'yourcomment': 'Váš komentář',
            'submit': 'Odeslat'
        },
        'nl' : {
            'back': 'Vorige',
            'comments': 'Commentaar',
            'typecommenthere': 'Schrijf commentaar hier',
            'yourcomment': 'Uw commentaar',
            'submit': 'Verzenden'
        },
        'fr' : {
            'back': 'Précédent',
            'comments': 'Commentaires',
            'typecommenthere': 'Écrire un commentaire ici',
            'yourcomment': 'Votre commentaire',
            'submit': 'Soumettre'
        },
        'de' : {
            'back': 'Zurück',
            'comments': 'Kommentare',
            'typecommenthere': 'Schreiben Sie Ihren Kommentar hier',
            'yourcomment': 'Ihr Kommentar',
            'submit': 'Senden'
        },
        'pl' : {
            'back': 'Wróć',
            'comments': 'Komentarze',
            'typecommenthere': 'Napisz komentarz tutaj',
            'yourcomment': 'Twój komentarz',
            'submit': 'Wyślij'
        },
        'es' : {
            'back': 'Atrás',
            'comments': 'Comentarios',
            'typecommenthere': 'Escriba su comentario aquí',
            'yourcomment': 'Su comentario',
            'submit': 'Enviar'
        }
      }
    };

  }
  render() {
    
    const shareUrl = 'https://foxlink.app/#!/event/'+ this.state.event.id + '/';
    const title = 'FOXLINK - ' + this.state.event.acf.title;
    
    return (
      <Page name="event">
        <Navbar title={`${this.state.event.acf.title}`} backLink={`${this.state.translate[localStorage.getItem('lang')].back}`} />
        
        <Swiper pagination>
          {this.state.event.acf.gallery.map((gallery_image, index) => (
            <SwiperSlide key={index}>
              <img alt={`${gallery_image.title}`} src={`${gallery_image.url}`} />
            </SwiperSlide>
          ))}
        </Swiper>

        <Block strong className="no-margin-bottom">
          <div dangerouslySetInnerHTML={{__html: this.state.event.acf.description}} />
          <Chip text=<Moment format="DD.MM.Y. HH:mm">{`${this.state.event.acf.datetime}`}</Moment> mediaBgColor="green">
            <Icon slot="media" f7="clock"></Icon>
          </Chip>
          <Chip text=<Moment format="DD.MM.Y. HH:mm">{`${this.state.event.acf.datetime_end}`}</Moment> mediaBgColor="red">
            <Icon slot="media" f7="clock"></Icon>
          </Chip>
        </Block>
        
        <Button 
          href={this.directionLink()}
          fill 
          external
          raised
          strong
          large
          color="orange" 
          iconF7="arrow_branch" 
        >
          {this.state.translate[localStorage.getItem('lang')].navigate}
        </Button>
        <Block>
         <Row className="Demo__container">
            <Col className="Demo__some-network">
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
    
            </Col>
    
            <Col className="Demo__some-network">
              <FacebookMessengerShareButton
                url={shareUrl}
                appId="521270401588372"
                className="Demo__some-network__share-button"
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
            </Col>
    
            <Col className="Demo__some-network">
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
    
            </Col>
    
            <Col className="Demo__some-network">
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
    
            </Col>
    
            <Col className="Demo__some-network">
              <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </Col>
    
          </Row>
        </Block>

        <Block>
          <BlockTitle>{`${this.state.translate[localStorage.getItem('lang')].comments}`}</BlockTitle>
          <List form onSubmit={(e)=>this.comment(e, this.state.event.id)}>
            <ListInput
              label={`${this.state.translate[localStorage.getItem('lang')].typecommenthere}`}
              type="textarea"
              placeholder={`${this.state.translate[localStorage.getItem('lang')].yourcomment}`}
              name="commentcontent"
            >
            </ListInput>
            <Button type="submit">{`${this.state.translate[localStorage.getItem('lang')].submit}`}</Button>
          </List>
            {(
              (() => {
                if (typeof(this.state.event._embedded) !== 'undefined') {
                  return <List mediaList>
                    {this.state.event._embedded.replies[0].map((reply, index) => (
                      <ListItem
                        key={index}
                        title={reply.author_name}
                        after={`${new Date(reply.date).getDate() + '.' + (new Date(reply.date).getMonth()+1) + '.' + new Date(reply.date).getFullYear() + '.' }`}
                        // text={`${reply.content.rendered}`}
                        text={`${reply.content.rendered.replace(/<[^>]+>/g, '') }`}
                      ></ListItem>
                    ))}
                    </List>;
                }
  
              })()
            )}
        </Block>
      </Page>
    );
  }
  
  directionLink() {
    
    var link = '//maps.google.com/maps?saddr=My%20Location&daddr=' + this.state.event.acf.location.lat + ',' + this.state.event.acf.location.lng + '&ll=';
    
    if (this.$f7.device.ios) {
      return 'maps:' + link;
    } else {
      return 'https:' + link;
    }
  }
  
  like(type, post_id) {
    
    if('vote' in localStorage) {
      if (localStorage.getItem('vote') == post_id) {
      }
    } else {
      this.$f7.request.post('https://foxlink.app/wp-admin/admin-ajax.php', {
        action: 'like_dislike', 
        post_id: post_id,
        type: type
      }, function (data) {
        localStorage.setItem('vote', post_id);
      });
    }

  }
  
  comment(e, post_id) {
    e.preventDefault();
    const app = this.$f7;
    const formData = app.form.convertToData(e.target);
		app.dialog.alert("Thanks!");
    
    this.$f7.request.post('https://foxlink.app/wp-admin/admin-ajax.php', {
      action: 'comment', 
      post_id: post_id,
      user_id: localStorage.getItem('userid'),
      content: formData.commentcontent
    });
  }

}