import React from 'react';
import { Page, Navbar, Block, Swiper, SwiperSlide } from 'framework7-react';

const WPUPLOADS = 'https://foxlink.app/wp-content/uploads/';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: props.f7route.context.partners,
      sheetOpened: false,
      translate: {
        'en' : {
            'back': 'Back'
        },
        'cs' : {
            'back': 'Dozadu'
        },
        'nl' : {
            'back': 'Vorige'
        },
        'fr' : {
            'back': 'Précédent'
        },
        'de' : {
            'back': 'Zurück'
        },
        'pl' : {
            'back': 'Wróć'
        },
        'es' : {
            'back': 'Atrás'
        }
      }
    };

  }
  render() {

    return (
      <Page>
        <Navbar title={`${this.state.partners.acf.title}`} backLink={`${this.state.translate[localStorage.getItem('lang')].back}`}></Navbar>

        <Block strong className="no-margin">
          <div dangerouslySetInnerHTML={{__html: this.state.partners.acf.description}} />
        </Block>

        <Block>
          <Swiper navigation params={{speed:500, slidesPerView: 2, spaceBetween: 20, preventClicksPropagation: false}}>
            {this.state.partners.acf.partners.map((partner, index) => (
              <SwiperSlide key={index}>
                  <img onClick={this.createSheet.bind(this, partner.name, partner.description, partner.link)} width="180" alt={`${partner.name}`} src={`${partner.partner_image}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Block>
        
      </Page>
    );
  }

  createSheet(name, description, link) {
    
    const self = this;
    const $ = self.$$;
    // Create sheet modal
    var websiteTranslate = {
      'en':'Website',
      'cs':'Webová stránka',
      'nl':'Website',
      'fr':'Site web',
      'de':'Website',
      'pl':'Strona internetowa',
      'es':'Sitio web',
    }
    var website = websiteTranslate[localStorage.getItem('lang')];
    
    if (!self.sheet) {
      self.sheet = self.$f7.sheet.create({
        content: `
          <div className="sheet-modal">
            <div className="toolbar">
              <div className="toolbar-inner justify-content-flex-end">
                <a href="#" className="link sheet-close">Close</a>
              </div>
            </div>
            <div className="sheet-modal-inner">
              <div className="page-content">
                <div className="block"><h4>`
                  + name +
                `</h4></div>
                <div className="block">`
                  + description +
                `</div>
                <a className="link external button button-large button-fill" href="` + link + `">` + website + `</a>
              </div>
            </div>
          </div>
        `.trim(),
      });
    }
    // Close inline sheet
    if ($('.partner-sheet.modal-in').length > 0) self.$f7.sheet.close('.partner-partner');
    // Open it
    self.sheet.open();
  }
  
};