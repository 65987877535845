import React from 'react';
import { Col, BlockTitle, Page, Navbar, Button, Block, Link, List, ListItem, ListInput, Chip, Icon, AccordionContent, Swiper, SwiperSlide, Row } from 'framework7-react';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      farmer: props.f7route.context.farmer,
      like: props.f7route.context.farmer.acf.like,
      dislike: props.f7route.context.farmer.acf.dislike,
      translate: {
        'en': {
          'basicInfo': 'Basic info',
          'contactInfo': 'Contact info',
          'openingHours': 'Opening hours',
          'aboutUs': 'About us',
          'ourProducts': 'Our products',
          'navigate': 'Get Directions',
          'back': 'Back',
          'comments': 'Comments',
          'typecommenthere': 'Type comment here',
          'yourcomment': 'Your comment',
          'submit': 'Submit'
        },
        'cs': {
          'basicInfo': 'Základní informace',
          'contactInfo': 'Kontaktní informace',
          'openingHours': 'Otevírací doba',
          'aboutUs': 'O nás',
          'ourProducts': 'Naše výrobky',
          'navigate': 'Trasa',
          'back': 'Dozadu',
          'comments': 'Komentáře',
          'typecommenthere': 'Napište komentář zde',
          'yourcomment': 'Váš komentář',
          'submit': 'Odeslat'
        },
        'nl': {
          'basicInfo': 'Basisinfo',
          'contactInfo': 'Contactinformatie',
          'openingHours': 'Openingstijden',
          'aboutUs': 'Over ons',
          'ourProducts': 'Onze producten',
          'navigate': 'Route',
          'back': 'Vorige',
          'comments': 'Commentaar',
          'typecommenthere': 'Schrijf commentaar hier',
          'yourcomment': 'Uw commentaar',
          'submit': 'Verzenden'
        },
        'fr': {
          'basicInfo': 'Informations de base',
          'contactInfo': 'Coordonnées',
          'openingHours': "Heures d'ouverture",
          'aboutUs': 'À propos de nous',
          'ourProducts': 'Nos produits',
          'navigate': 'Itinéraire',
          'back': 'Précédent',
          'comments': 'Commentaires',
          'typecommenthere': 'Écrire un commentaire ici',
          'yourcomment': 'Votre commentaire',
          'submit': 'Soumettre'
        },
        'de': {
          'basicInfo': 'Grundlegende Informationen',
          'contactInfo': 'Kontaktinformationen',
          'openingHours': 'Öffnungszeiten',
          'aboutUs': 'Über uns',
          'ourProducts': 'Unsere Produkte',
          'navigate': 'Route',
          'back': 'Zurück',
          'comments': 'Kommentare',
          'typecommenthere': 'Schreiben Sie Ihren Kommentar hier',
          'yourcomment': 'Ihr Kommentar',
          'submit': 'Senden'
        },
        'pl': {
          'basicInfo': 'Podstawowe informacje',
          'contactInfo': 'Dane kontaktowe',
          'openingHours': 'Godziny otwarcia',
          'aboutUs': 'O nas',
          'ourProducts': 'Nasze produkty',
          'navigate': 'Wyznacz trasę',
          'back': 'Wróć',
          'comments': 'Komentarze',
          'typecommenthere': 'Napisz komentarz tutaj',
          'yourcomment': 'Twój komentarz',
          'submit': 'Wyślij'
        },
        'es': {
          'basicInfo': 'Información básica',
          'contactInfo': 'Información de contacto',
          'openingHours': 'Horario de apertura',
          'aboutUs': 'Sobre nosotros',
          'ourProducts': 'Nuestros productos',
          'navigate': 'Como llegar',
          'back': 'Atrás',
          'comments': 'Comentarios',
          'typecommenthere': 'Escriba su comentario aquí',
          'yourcomment': 'Su comentario',
          'submit': 'Enviar'
        }
      }
    };
  }
  render() {
    
    const shareUrl = 'https://foxlink.app/#!/farmer/'+ this.state.farmer.id + '/';
    const title = 'FOXLINK - ' + this.state.farmer.acf.title;

    return (
      <Page name="farmer">
        <Navbar title={`${this.state.farmer.acf.name}`} backLink={`${this.state.translate[localStorage.getItem('lang')].back}`} />
        <Swiper pagination>
          {this.state.farmer.acf.gallery.map((gallery_image, index) => (
            <SwiperSlide key={index}>
              <img alt={`${gallery_image.title}`} src={`${gallery_image.url}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <List accordionList className="no-margin-top no-margin-bottom">
          <ListItem accordionItem title={this.state.translate[localStorage.getItem('lang')].basicInfo}>
            <AccordionContent>
              <Block strong>
                <div dangerouslySetInnerHTML={{__html: this.state.farmer.acf.basic_info}} />
              </Block>
            </AccordionContent>
          </ListItem>

          <ListItem accordionItem title={this.state.translate[localStorage.getItem('lang')].contactInfo}>
            <AccordionContent>
            <List>
              <ListItem header="Name" title={`${this.state.farmer.acf.contact_name}`}>
              </ListItem>
              <ListItem external link={`tel:${this.state.farmer.acf.phone}`} header="Phone" title={`${this.state.farmer.acf.phone}`} footer="Mobile" after="Call">
                <Icon slot="media" ios="f7:phone_circle" aurora="f7:phone_circle" md="material:phone_circle"></Icon>
              </ListItem>
              <ListItem external link={`mailto:${this.state.farmer.acf.email}`}  header="Email" title={`${this.state.farmer.acf.email}`} after="Send email">
                <Icon slot="media" ios="f7:envelope_circle" aurora="f7:envelope_circle" md="material:envelope_circle"></Icon>
              </ListItem>
              <ListItem external link={`${this.state.farmer.acf.url}`} header="Web" title={`${this.state.farmer.acf.url_text}`} after="Visit site">
                <Icon slot="media" ios="f7:globe" aurora="f7:globe" md="material:globe"></Icon>
              </ListItem>
            </List>
            </AccordionContent>
          </ListItem>
          {(
            (() => {
              if ( this.state.farmer.acf.mon || this.state.farmer.acf.tue || this.state.farmer.acf.wed || this.state.farmer.acf.thu || this.state.farmer.acf.fri || this.state.farmer.acf.sat || this.state.farmer.acf.sun )
              return <ListItem accordionItem title={this.state.translate[localStorage.getItem('lang')].openingHours}>
                <AccordionContent>
                  <Block className="opening-hours" strong>
                  {(
                    (() => {
                      if (this.state.farmer.acf.mon )
                        return <Chip text={`${this.state.farmer.acf.mon}`} mediaBgColor="green" media="Mon" />;
                    })()
                  )}
                  {(
                    (() => {
                      if (this.state.farmer.acf.tue )
                        return <Chip text={`${this.state.farmer.acf.tue}`} mediaBgColor="green" media="Tue" />;
                    })()
                  )}
                  {(
                    (() => {
                      if (this.state.farmer.acf.wed )
                        return <Chip text={`${this.state.farmer.acf.wed}`} mediaBgColor="green" media="Wed" />;
                    })()
                  )}
                  {(
                    (() => {
                      if (this.state.farmer.acf.thu )
                        return <Chip text={`${this.state.farmer.acf.thu}`} mediaBgColor="green" media="Thu" />;
                    })()
                  )}
                  {(
                    (() => {
                      if (this.state.farmer.acf.fri )
                        return <Chip text={`${this.state.farmer.acf.fri}`} mediaBgColor="green" media="Fri" />;
                    })()
                  )}
                  {(
                    (() => {
                      if (this.state.farmer.acf.sat )
                        return <Chip text={`${this.state.farmer.acf.sat}`} mediaBgColor="orange" media="Sut" />;
                    })()
                  )}
                  {(
                    (() => {
                      if (this.state.farmer.acf.sun )
                        return <Chip text={`${this.state.farmer.acf.sun}`} mediaBgColor="blue" media="Sun" />;
                    })()
                  )}
                  </Block>
                </AccordionContent>
              </ListItem>;
              })()
          )}
          <ListItem accordionItem title={this.state.translate[localStorage.getItem('lang')].aboutUs}>
            <AccordionContent>
              <Block strong>
                <div dangerouslySetInnerHTML={{__html: this.state.farmer.acf.interviews}} />
              </Block>
            </AccordionContent>
          </ListItem>
          {(
            (() => {
              if (this.state.farmer.acf.products) {
                return <ListItem accordionItem title={this.state.translate[localStorage.getItem('lang')].ourProducts}>
                  <AccordionContent>
                    <List className="farmerProducts" mediaList>
                        {this.state.farmer.acf.products.map((product, index) => (
                          <ListItem key={index}
                            title={product.product.post_title}
                            text={product.product_description}
                          >
                            <img
                              slot="media"
                              src={product.product_image}
                              width="80"
                            />
                          </ListItem>
                          ))}
          
                    </List>
                  </AccordionContent>
                </ListItem>;
              }
            })()
          )}
        </List>
        <Button 
          href={this.directionLink()}
          fill 
          external
          raised
          strong
          large
          color="orange" 
          iconF7="arrow_branch" 
        >
          {this.state.translate[localStorage.getItem('lang')].navigate}
        </Button>

        <Block strong className="no-margin-bottom">
          <Link onClick={() => {
            this.like('like', this.state.farmer.id);
            this.setState({like: '+'});
          }}>
            <Chip margin-left text={`${this.state.like}`} outline mediaBgColor="green">
              <Icon slot="media" f7="hand_thumbsup"></Icon>
            </Chip>
          </Link>
          <Link onClick={() => {
            this.like('dislike', this.state.farmer.id);
            this.setState({dislike: '+'});
          }}>
            <Chip text={`${this.state.dislike}`} mediaBgColor="red">
              <Icon slot="media" f7="heart_slash"></Icon>
            </Chip>
          </Link>
        </Block>
        <Block>
             <Row className="Demo__container">
                <Col className="Demo__some-network">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
        
                </Col>
        
                <Col className="Demo__some-network">
                  <FacebookMessengerShareButton
                    url={shareUrl}
                    appId="521270401588372"
                    className="Demo__some-network__share-button"
                  >
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton>
                </Col>
        
                <Col className="Demo__some-network">
                  <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
        
                </Col>
        
                <Col className="Demo__some-network">
                  <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
        
                </Col>
        
                <Col className="Demo__some-network">
                  <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </Col>

              </Row>
        </Block>
        <Block>
          <BlockTitle>{`${this.state.translate[localStorage.getItem('lang')].comments}`}</BlockTitle>
          <List form onSubmit={(e)=>this.comment(e, this.state.farmer.id)}>
            <ListInput
              label={`${this.state.translate[localStorage.getItem('lang')].typecommenthere}`}
              type="textarea"
              placeholder={`${this.state.translate[localStorage.getItem('lang')].yourcomment}`}
              name="commentcontent"
            >
            </ListInput>
            <Button type="submit">{`${this.state.translate[localStorage.getItem('lang')].submit}`}</Button>
          </List>
            {(
              (() => {
                if (typeof(this.state.farmer._embedded) !== 'undefined') {
                  return <List mediaList>
                    {this.state.farmer._embedded.replies[0].map((reply, index) => (
                      <ListItem
                        key={index}
                        title={reply.author_name}
                        after={`${new Date(reply.date).getDate() + '.' + (new Date(reply.date).getMonth()+1) + '.' + new Date(reply.date).getFullYear() + '.' }`}
                        // text={`${reply.content.rendered}`}
                        text={`${reply.content.rendered.replace(/<[^>]+>/g, '') }`}
                      ></ListItem>
                    ))}
                    </List>;
                }
  
              })()
            )}
        </Block>
        
      </Page>
    );
  }

  directionLink() {

    var link = '//maps.google.com/maps?saddr=My%20Location&daddr=' + this.state.farmer.acf.location.lat + ',' + this.state.farmer.acf.location.lng + '&ll=';

    if (this.$f7.device.ios) {
      return 'maps:' + link;
    }
    else {
      return 'https:' + link;
    }
  }
  
  like(type, post_id) {
    
    if('vote' in localStorage) {
      if (localStorage.getItem('vote') == post_id) {
      }
    } else {
      this.$f7.request.post('https://foxlink.app/wp-admin/admin-ajax.php', {
        action: 'like_dislike', 
        post_id: post_id,
        type: type
      }, function (data) {
        localStorage.setItem('vote', post_id);
      });
    }

  }
  
  comment(e, post_id) {
    e.preventDefault();
    const app = this.$f7;
    const formData = app.form.convertToData(e.target);
		app.dialog.alert("Thanks!");
    
    this.$f7.request.post('https://foxlink.app/wp-admin/admin-ajax.php', {
      action: 'comment', 
      post_id: post_id,
      user_id: localStorage.getItem('userid'),
      content: formData.commentcontent
    });
  }
  
}
