import React from 'react';
import { Page, Navbar, List, ListItem, Searchbar, Subnavbar } from 'framework7-react';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      NewsCategoryListPosts: props.f7route.context.NewsCategoryListPosts,
      translate: {
        'en': {
          'readmore': 'Read more...',
          'news': 'News',
          'newsandupdates': 'News and updates for you',
          'back': 'Back'
        },
        'cs': {
          'readmore': 'Přečtěte si více...',
          'news': 'Novinky',
          'newsandupdates': 'Novinky a aktualizace pro vás',
          'back': 'Dozadu'
        },
        'nl': {
          'readmore': 'Lees meer...',
          'news': 'Nieuws en update voor u',
          'newsandupdates': 'Nieuws en updates voor u',
          'back': 'Vorige'
        },
        'fr': {
          'readmore': 'Lire la suite...',
          'news': 'Actualités',
          'newsandupdates': 'Nouvelles et mises à jour pour toi',
          'back': 'Précédent'
        },
        'de': {
          'readmore': 'Mehr lesen...',
          'news': 'Neuigkeiten',
          'newsandupdates': 'Neuigkeiten und Updates für dich',
          'back': 'Zurück'
        },
        'pl': {
          'readmore': 'Czytaj więcej...',
          'news': 'Aktualności',
          'newsandupdates': 'Aktualności i aktualizacje dla Ciebie',
          'back': 'Wróć'
        },
        'es': {
          'readmore': 'Leer más...',
          'news': 'Noticias',
          'newsandupdates': 'Noticias y novedades para ti',
          'back': 'Atrás'
        }
      }
    };

  }

  render() {
    return (
      <Page name="NewsCategoryListPosts">
        {/* <Navbar title={`${this.state.translate[localStorage.getItem('lang')].newsandupdates}`} backLink={`${this.state.translate[localStorage.getItem('lang')].back}`}> */}
        <Navbar backLink={`${this.state.translate[localStorage.getItem('lang')].back}`}>
          <Subnavbar inner={false}>
            <Searchbar
              placeholder=" "
              searchContainer=".search-list"
              searchIn=".item-title"
              disableButton={!this.$theme.aurora}
            ></Searchbar>
          </Subnavbar>
        </Navbar>
        <List className="searchbar-not-found">
          <ListItem title="Nothing found" />
        </List>
        <List className="search-list searchbar-found no-margin" mediaList>
          {this.state.NewsCategoryListPosts.map((posts, index) => (
          <ListItem
            key={index}
            link={`/news/${posts.id}/`}
            title={`${posts.title.rendered}`}
            subtitle={`${this.state.translate[localStorage.getItem('lang')].readmore}`}>
            {(
              (() => {
                if (posts.featured_image) {
                  return <img slot="media" src={`${posts.featured_image.sizes.thumbnail}`} width="44" />;
                }
              })()
            )}
          </ListItem>
          ))}
        </List>
        
      </Page>
    );
  }

}
