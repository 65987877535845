import React from 'react';
import {
  AccordionContent,
  Button,
  Page,
  Navbar,
  Popover,
  NavLeft,
  NavTitle,
  NavTitleLarge,
  NavRight,
  Link,
  PageContent,
  Block,
  BlockTitle,
  List,
  ListItem,
  Sheet,
  Swiper,
  SwiperSlide,
  Toolbar
}
from 'framework7-react';

import PWAPrompt from 'react-ios-pwa-prompt';

import YouTube from 'react-youtube';

export default class extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      frontpage: props.f7route.context.frontpage,
      lang: localStorage.getItem('lang'),
      
      translate: {
        'en': {
          'readmore': 'Read more...',
          'newsfeed': 'News feed',
          'video': 'Food in a Box - Video',
        },
        'cs': {
          'readmore': 'Přečtěte si více...',
          'newsfeed': 'Zpravodajský kanál',
          'video': 'Jídlo v krabici - Video',
        },
        'nl': {
          'readmore': 'Lees meer...',
          'newsfeed': 'News feed',
          'video': 'Voedsel in een box - Video',
        },
        'fr': {
          'readmore': 'Lire la suite...',
          'newsfeed': 'Fil d\'actualités',
          'video': 'Aliments en boîte - Vidéo',
        },
        'de': {
          'readmore': 'Mehr lesen...',
          'newsfeed': 'News feed',
          'video': 'Lebensmittel in einer Box - Video',
        },
        'pl': {
          'readmore': 'Czytaj więcej...',
          'newsfeed': 'Aktualności',
          'video': 'Jedzenie w pudełku - Wideo',
        },
        'es': {
          'readmore': 'Leer más...',
          'newsfeed': 'Noticias',
          'video': 'Comida en una caja - Video',
        }
      }
    };
    
  }

  render() {
    const opts = {
      height: '250px',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        controls: 0,
        loop: 1,
        modestbranding: 1
      }
    };
    return (
      <Page name="home">
        <PWAPrompt timesToShow={5} permanentlyHideOnDismiss={false} copyTitle="Install FOXLINK App"/>

        {/* Top Navbar */}
        <Navbar sliding={false} large>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
          </NavLeft>
          <NavTitle sliding>FOXLINK</NavTitle>
          <NavRight>
            <Link iconIos="f7:map_pin_ellipse" iconAurora="f7:map_pin_ellipse" iconMd="material:map_pin_ellipse" popoverOpen=".region-menu" />
            <Link iconIos="f7:person" iconAurora="f7:person" iconMd="material:person" panelOpen="right" />
          </NavRight>
          <NavTitleLarge>FOXLINK</NavTitleLarge>
        </Navbar>

        <Popover className="region-menu">
          <List>
            {(
              (() => {
                if (localStorage.getItem('userrole') == "administrator" || localStorage.getItem('userrole') == "editor") {
                  return <ListItem link="#" onClick={() => this.changeLang('en')} title="Admin" />;
                }
              })()
            )}
          
            <ListItem link="#" onClick={() => this.changeLang('de')} title="Deutschland" />
            <ListItem link="#" onClick={() => this.changeLang('cs')} title="Česko" />
            <ListItem link="#" onClick={() => this.changeLang('nl')} title="Nederland" />
            <ListItem link="#" onClick={() => this.changeLang('pl')} title="Polska" />
            <ListItem link="#" onClick={() => this.changeLang('fr')} title="France" />
            <ListItem link="#" onClick={() => this.changeLang('es')} title="España" />
          </List>
        </Popover>

        {/* Page content */}
        <Swiper pagination>
          {this.state.frontpage.acf.slider.map((slider, index) => (
          <SwiperSlide key={index}>
            <img alt="Slide" src={`${slider.image}`} />
            <Block className="no-margin text-color-white sliderContent">
              <p dangerouslySetInnerHTML={{__html: slider.title}} />
              <Button className="sliderButton" href={`${slider.link}`} fill>{this.state.translate[localStorage.getItem('lang')].readmore}</Button>
            </Block>
          </SwiperSlide>
          ))}
          
          {(
            (() => {
              if (this.state.frontpage.acf.youtube) {
              return <SwiperSlide>
                <img alt="Benefits" src="static/img/video.png" />
                <Block className="no-margin text-color-white sliderContent">
                  <p>{this.state.translate[localStorage.getItem('lang')].video}</p>
                  <Button className="sliderButton" sheetOpen=".youtube-sheet" fill>Video</Button>
                </Block>
              </SwiperSlide>
              }
            })()
          )}
        </Swiper>
        <List className="no-margin-top" accordionList>
          {this.state.frontpage.acf.intro.map((accordion, index) => (
          <ListItem accordionItem key={index} title={`${accordion.intro_title}`}>
            <AccordionContent>
              <Block>
                <p dangerouslySetInnerHTML={{__html: accordion.intro_description}} />
              </Block>
            </AccordionContent>
          </ListItem>
          ))}
        </List>
     
        <Block className="no-margin-top text-align-center bg-color-deeppurple" strong>
        <BlockTitle className="no-margin text-color-white">{this.state.translate[localStorage.getItem('lang')].newsfeed}</BlockTitle>
        </Block>
      
        <List mediaList>
          {this.state.frontpage.acf.news.map((post, index) => (
              (() => {
                if (post.post_status=='publish') {
                  return <ListItem
                    key={index}
                    link={`/news/${post.ID}/`}
                    title={`${post.post_title}`}
                    subtitle={`${this.state.translate[localStorage.getItem('lang')].readmore}`}>
                    <img slot="media" src={`${post.featured_image}`} width="44" />
                  </ListItem>;
                }
  
              })()
          ))}
        </List>
        <List className="site-links no-margin">
          <ListItem link="/food-in-a-box/" title={`${this.state.frontpage.acf.foodinabox_title}`}/>
          <ListItem link="/partners/" title={`${this.state.frontpage.acf.partners_title}`}/>
          <ListItem external target="_blank" link={`${this.state.frontpage.acf.url}`} title={`${this.state.frontpage.acf.link_title}`}/>
        </List>

        <Sheet 
          className="youtube-sheet" 
          opened={this.state.sheetOpened} 
          style={{height: '50%'}}
          onSheetClosed={() => {this.setState({sheetOpened: false})}}
        >
          <Toolbar>
            <div className="left"></div>
            <div className="right">
              <Link sheetClose>Close</Link>
            </div>
          </Toolbar>
          {/*  Scrollable sheet content */}
          <PageContent>
            <Block>
              <YouTube videoId={`${this.state.frontpage.acf.youtube}`} opts={opts} onReady={this.yt_video_onReady} />
            </Block>
          </PageContent>
        </Sheet>
        
      </Page>
    );
  }
  
  changeLang(lang) {
    localStorage.setItem('lang', lang);
    document.cookie = "wp-wpml_current_language=" + lang;
    window.location.reload(false);
    
  }
  
  yt_video_onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

}
