import React from 'react';
import { Page, Navbar, Link } from 'framework7-react';
import Moment from 'react-moment';
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@google/markerclusterer';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: props.f7route.context.events,
      defaultCenter: {
        'en': {
          'lat': 50.8450745,
          'lng': 4.3120767,
          'zoom': 6
        },
        'de': {
          'lat': 51.0899471,
          'lng': 5.9689811,
          'zoom': 5
        },
        'cs': {
          'lat': 50.0595854,
          'lng': 12.2244065,
          'zoom': 7
        },
        'nl': {
          'lat': 52.1917379,
          'lng': 4.1576857,
          'zoom': 7
        },
        'pl': {
          'lat': 51.8684417,
          'lng': 14.649532,
          'zoom': 6
        },
        'fr': {
          'lat': 46.1314019,
          'lng': -2.4352892,
          'zoom': 7
        },
        'es': {
          'lat': 40.1217279,
          'lng': -8.2021731,
          'zoom': 4
        }
      },
      translate: {
        'en': {
          'subscribers': 'Subscribers',
          'events': 'Subscribers'
        },
        'cs': {
          'subscribers': 'Předplatitelé',
          'events': 'Události'
        },
        'nl': {
          'subscribers': 'Abonnees',
          'events': 'Evenementen'
        },
        'fr': {
          'subscribers': 'Abonnés',
          'events': 'Événements'
        },
        'de': {
          'subscribers': 'Abonnenten',
          'events': 'Veranstaltungen'
        },
        'pl': {
          'subscribers': 'Abonenci',
          'events': 'Wydarzenia'
        },
        'es': {
          'subscribers': 'Suscriptores',
          'events': 'Eventos'
        }
      }
    };
  }

  render() {
    return (
      <Page name="events">
        <Navbar title={`${this.state.translate[localStorage.getItem('lang')].events}`} />
        
        <div style={{ height: '25vh', width: '100%', position: 'fixed', zIndex: 1 }} className="events-map">
          <GoogleMapReact
            bootstrapURLKeys={{key: `AIzaSyD4upTz-ZvVD7dNlSGL4X4EGSF0ItsHvOE`}}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({map, maps}) => this.setGoogleMapRef(map, maps)}
            defaultCenter={{lat: this.state.defaultCenter[localStorage.getItem('lang')].lat, lng: this.state.defaultCenter[localStorage.getItem('lang')].lng}}
            defaultZoom={this.state.defaultCenter[localStorage.getItem('lang')].zoom}
            options={{streetViewControl: true}}
          />
        </div>
        
        <div className="timeline timeline-sides">
          {this.state.events.map((event, index) => (
          <div className="timeline-item" key={index}>
            <div className="timeline-item-date"><Moment format="DD">{event.acf.datetime}</Moment> <small><Moment format="MMM">{event.acf.datetime}</Moment></small></div>
            <div className="timeline-item-divider"></div>
            <div className="timeline-item-content">
              <div className="timeline-item-inner organic-day"><Link color="black" href={`/event/${event.id}/`}>{event.acf.title}</Link></div>
            </div>
          </div>
          ))}
        </div>
      </Page>
    );
  }

  setGoogleMapRef(map, maps) {
    
    var acfLocations = [];
    {this.state.events.map((event, index) => (
      acfLocations.push(
      { lat: event.acf.location.lat, lng: event.acf.location.lng })
    ))}
    
    this.googleMapRef = map;
    this.googleRef = maps;
    let locations = acfLocations;
    let markers = locations && locations.map((location) => {
      return new this.googleRef.Marker({ position: location });
    });
    let markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      gridSize: 10,
      minimumClusterSize: 2
    });
  }

}
