import React from 'react';
import { Page, Navbar, Block, BlockTitle } from 'framework7-react';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      foodinabox: props.f7route.context.foodinabox,
      translate: {
        'en' : {
            'back': 'Back'
        },
        'cs' : {
            'back': 'Dozadu'
        },
        'nl' : {
            'back': 'Vorige'
        },
        'fr' : {
            'back': 'Précédent'
        },
        'de' : {
            'back': 'Zurück'
        },
        'pl' : {
            'back': 'Wróć'
        },
        'es' : {
            'back': 'Atrás'
        }
      }
    };

  }

  render() {
    return (
    <Page name="food-in-a-box">
      <Navbar title={`${this.state.foodinabox.acf.title}`} backLink={`${this.state.translate[localStorage.getItem('lang')].back}`} />
      {this.state.foodinabox.acf.food_in_a_box.map((foodinabox, index) => (
        <div key={index}>
          <Block className="borderLeft" strong>
            <BlockTitle className="no-margin">{foodinabox.title}</BlockTitle>
          </Block>
          <Block>
            <div dangerouslySetInnerHTML={{__html: foodinabox.text}} />
          </Block>
        </div>
      ))}
    </Page>
    );
  }
}